const hr = {
  R_SOUND:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/Kako-se-pravilno-izgovara-R.mp4',
  R_SOUND_EXERCISE_EXAMPLE:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/Primjer-vjezbe-za+glas-R-Cetkajmo+gusarske-zube.mp4',
  KOKOLINGO:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/kokolingo.mp4',
  // š
  S1_SOUND:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/%C5%A0-Uvodna-edukacija.mp4',
  S1_EXERCISE_EXAMPLE:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/Ograda-na-palubi.mp4',
};

const pl = {
  KOKOLINGO:
    'https://kokolingo-poland-data.s3.eu-central-1.amazonaws.com/videos/kokolingo.mp4',
};

// eslint-disable-next-line camelcase
const en_uk = {
  KOKOLINGO:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/kokolingo.mp4',
};

// eslint-disable-next-line camelcase
const en_us = {
  KOKOLINGO:
    'https://kokolingo-croatia-data.s3.eu-central-1.amazonaws.com/videos/kokolingo.mp4',
};

const videos = { hr, pl, 'en-uk': en_uk, 'en-us': en_us };

export default videos[process.env.GATSBY_LOCALE];
