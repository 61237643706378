import * as React from 'react';
import { Link } from 'gatsby';

import { Text, BlogImage, BlogLink, BlogVideo } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import routes from '../../../routes';

import colouring from '../../../images/educational-corner/blog20/glas-R-bojenje.png';
import talkingCards from '../../../images/educational-corner/blog20/glas-R-brbljave-kartice.png';
import fortuneWheel from '../../../images/educational-corner/blog20/glas-R-kolo-srece.png';
import memory from '../../../images/educational-corner/blog20/glas-R-memori.png';
import discover from '../../../images/educational-corner/blog20/glas-R-otkrij.png';
import climbThePalm from '../../../images/educational-corner/blog20/glas-R-popni-se-na-palmu.png';
import stories from '../../../images/educational-corner/blog20/glas-R-price.png';
import treasureHunting from '../../../images/educational-corner/blog20/glas-R-skupljanje-blaga.png';
import correctRImage from '../../../images/gusar-Srecko-glas-R.jpg';
import exampleRImage from '../../../images/gusar-Srecko-cetkajmo-zube.jpg';
import videos from '../../../videos';

const PracticeVoiceR = () => {
  return (
    <PageNew
      title="KOKOLINGO - Izgovor glasa R"
      description="Velik broj djece ima problema upravo s izgovorom glasa R. 
      Glas R obično se pojavljuje u dobi između 4 i pol i 5 godina. 
      Ako je vaše dijete mlađe od te dobi, ne morate se još brinuti. 
      No, ako je vaše dijete starije i još uvijek ne može izgovoriti R, 
      vrijeme je da krenete u akciju."
      headline="Kako uvježbati glas R?"
    >
      <Text as="p">
        Mnogi roditelji javljaju se s pitanjem: „Zašto moje dijete ne može reći
        R?” ili pak: „Kako moje dijete može naučiti reći slovo R?”.
      </Text>
      <Text as="p">
        Velik broj djece ima problema upravo s izgovorom glasa R. Glas R obično
        se pojavljuje u dobi između 4 i pol i 5 godina. Ako je vaše dijete mlađe
        od te dobi, ne morate se još brinuti. No, ako je vaše dijete starije i
        još uvijek ne može izgovoriti R, vrijeme je da krenete u akciju.
        Pravovremeni početak rješavanja artikulacijskih poremećaja jako je važan
        za uspjeh vašeg djeteta.
      </Text>
      <Text as="p">Ovdje možete vidjeti kako se pravilno izgovara glas R:</Text>
      <BlogVideo
        src={videos.R_SOUND}
        title="Pravilan izgovor glasa R"
        poster={correctRImage}
      />
      <Text as="p">
        Neka djeca glas R zamjenjuju glasovima J i L. Tako će neki umjesto
        „riba“ reći „jiba“ ili pak „liba“. Drugi je slučaj da ponekad jezik ne
        vibrira dovoljno ili se glas R uopće ne čuje.
      </Text>
      <Text as="p">
        Jako je važno znati da se s vježbama za glas R može početi tek kada
        dijete može pravilno izgovoriti glas L.
      </Text>
      <Text as="p">
        Kao i kod svakog glasa, i kod glasa R može se raditi o artikulacijskom
        ili o fonološkom poremećaju, a ponekad i o kombinaciji oba.
        Artikulacijski poremećaj izgovora pojedinih glasova uzrokuje pogrešna
        pozicija govornih organa tijekom izgovora, a kod fonoloških poremećaja
        izgovor određenog glasa ovisi o njegovoj poziciji unutar riječi ili o
        susjednim glasovima. Detaljnije pročitajte{' '}
        <Link
          to={routes.SPEECH_DISORDER}
          title="Artikulacijski poremećaji"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>ovdje</b>
        </Link>
        .
      </Text>
      <Text as="p">
        Svakako je važno da se javite stručnjaku - logopedu koji je
        najkompetentniji da napravi dijagnostiku i uputi vas kako najbolje
        pomoći svome djetetu. Pored rada s logopedom, potreban je i redoviti rad
        s djetetom kod kuće. Jedino kombinacija redovite terapije i redovitog
        svakodnevnog vježbanja kod kuće može dati najbolje rezultate. Digitalna
        logopedska vježbenica <BlogLink>Kokolingo</BlogLink> odlično se uklapa u
        rad na logopedskoj terapiji, kao i rad s djetetom kod kuće. Ona, naime,
        umjesto dugih nizova riječi koje treba ponavljati jednu za drugom,
        umjesto različitih kopiranih materijala koji ponekad djeci znaju i
        dosaditi, nudi sve te materijale kroz igre, spajalice, slagalice,
        otkrivalice i još mnogo toga. A još mnogo detaljnije o Kokolingu možete
        pročitati u{' '}
        <Link
          href={routes.IMPORTANCE_OF_SPEECH_THERAPY_AT_HOME}
          title="Vodenje logopedskih vjezbi kod kuce"
          target="_blank"
          hrefLang="hr"
          rel="noopener noreferrer"
        >
          <b>našem članku</b>
        </Link>
        .
      </Text>
      <Text as="p">
        Poznato je da za glas R obično treba puno strpljivosti i višemjesečni
        redoviti rad kako bi se postigao napredak.{' '}
        <BlogLink>Kokolingo</BlogLink> smo razvili upravo tako da bi dijete s
        puno više motivacije svakodnevno kod kuće vježbalo izgovor.
      </Text>
      <Text as="p">
        Vježbe u <BlogLink>Kokolingo</BlogLink> logopedskoj vježbenici
        podijeljene su u nekoliko cjelina:
        <ul>
          <li>Artikulacijska gimnastika</li>
          <li>Slušno razlikovanje glasova</li>
          <li>Vježbe izgovora slogova i riječi</li>
          <li>Vježbe s rečenicama i pričama</li>
        </ul>
      </Text>
      <Text as="h2">Artikulacijska gimnastika</Text>
      <Text as="p">
        Kao pomoć u ispravljanju artikulacijskih poremećaja u digitalnoj
        logopedskoj vježbenici <BlogLink>Kokolingo</BlogLink> naći ćete vježbe
        artikulacijske gimnastike. Pod nazivom Gusarska gimnastika, ove su
        predvježbe temelj na koji se naslanjaju sve ostale vježbe i ne smiju se
        preskočiti. Podijeljene su na opće vježbe artikulacijske gimnanstike
        koje su zajedničke za sve glasove i specifične vježbe za pojedine
        glasove. Tako ćete za glas R pronaći vježbe zanimljivih imena poput:
        Četkajmo gusarske zube, Branje gljiva i Rakova kliješta. Neke su vježbe
        statične, u kojima se govorni organi određeno vrijeme drže u određenom
        položaju, neke su dinamične, u kojima s artikulatorima treba odraditi
        određene radnje, a neke su statičko-dinamičke. Jednu takvu vježbu za
        glas R možete pogledati u sljedećem video isječku:
      </Text>
      <BlogVideo
        src={videos.R_SOUND_EXERCISE_EXAMPLE}
        title="Primjer vjezbe za izgovor glasa R"
        poster={exampleRImage}
      />
      <Text as="p">
        U <BlogLink>Kokolingo</BlogLink> vježbenici mnogo je takvih vježbi. Zato
        vas pozivamo da ih pogledate, iskušate se u gusarskoj gimnastici i još
        danas započnete s vježbanjem!
      </Text>
      <Text as="h2">Pažljivo slušanje</Text>
      <Text as="p">
        Sljedeća stepenica u <BlogLink>Kokolingo</BlogLink> svijetu je Pažljivo
        slušanje. To je skup vježbi fonemske diskriminacije, odnosno međusobnog
        razlikovanja glasova. Potrebno je prijeći ovu stepenicu, naučiti čuti i
        prepoznati razliku između dva slična glasa, kako bi se moglo uspješno
        uvježbati pravilan izgovor. Pažljivo slušanje dolazi u dvije zabavne
        vježbe, Skupljanje blaga i Bojanje. U svakoj je potrebno poslušati riječ
        i odlučiti koji se glas čuje te tako prikupiti po jedan vrijedni novčić.
      </Text>
      <BlogImage
        imgSrc={treasureHunting}
        imgAlt="Prikaz igre gdje se za pogodeni glas skuplja blago"
        imgTitle="Skupljanje blaga"
      />
      <BlogImage
        imgSrc={colouring}
        imgAlt="Prikaz igre gdje se mora pogoditi koji glas pripada kojem objektu"
        imgTitle="Bojenje"
      />
      <Text as="h2">Logatomi i riječi</Text>
      <Text as="p">
        U avanturama na Otoku palmi, koraljnom grebenu, Pustom otoku i Ledenom
        otoku s djecom možete vježbati logatome i riječi. Logatomi su slogovi
        koji sami po sebi nemaju značenja, ali, baš kao i riječi, u{' '}
        <BlogLink>Kokolingo</BlogLink> će nam svijetu dobro poslužiti za
        vježbanje glasa R kroz zabavne aktivnosti, i to na početku, u sredini i
        na kraju riječi. Vježbe ili igre, kako vam je draže nazvati ih, zovu se
        Popni se i Otkrij te Brbljave kartice, Pronađi riječi i Memori kartice.
        Možete li zamisliti koliko se samo različitih riječi i logatoma krije na
        toliko različitih otoka? I koliko samo svjetova i njihovih bića,
        podmorskih, pustinjskih, ledenih?{' '}
        <Link
          href={routes.ONLINE_SPEECH_THERAPY_EXERCISES}
          title="Kokolingo aplikacija"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>Ovdje možete baciti pogled i nakratko prošetati našim svijetom</b>
        </Link>
        .
      </Text>
      <BlogImage
        imgSrc={climbThePalm}
        imgAlt="Prikaz igre gdje se morate popeti na vrh palme za svaki pogodenu rijec s tocnim glasom"
        imgTitle="Penjanje na palmu"
      />
      <BlogImage
        imgSrc={discover}
        imgAlt="Prikaz igre gdje se za pogodenu rijec s tocnim glasom otkriva slika"
        imgTitle="Otkrij"
      />
      <BlogImage
        imgSrc={talkingCards}
        imgAlt="Prikaz igre gdje se klikom na kartice cuje izgovor objekta sa slike"
        imgTitle="Brbljave kartice"
      />
      <BlogImage
        imgSrc={memory}
        imgAlt="Prikaz igre gdje se moraju traziti parovi"
        imgTitle="Memori"
      />
      <Text as="h2">Rečenice i priče</Text>
      <Text as="p">
        Dijete koje je uvježbalo glas R u logatomima i riječima može otploviti
        na Prašumski otok gdje u igrama nadopunjavanja rečenica polako uči
        izgovarati riječi u rečenicama. I najzad, na Otočnoj oazi dočekat će ga
        posljednja stepenica, slušanje i izgovaranje rečenica u pričama te
        razumijevanje pročitanoga. Puno je ovdje vježbi za male moreplovce koji
        su uspješno doplovili do posljednjih otoka. Još samo jedan korak, korak
        koji zahtijeva puno predane vježbe i koncentracije, i glas R je
        savladan!
      </Text>
      <BlogImage
        imgSrc={stories}
        imgAlt="Prikaz igre gdje se stvara prica"
        imgTitle="Price"
      />
      <BlogImage
        imgSrc={fortuneWheel}
        imgAlt="Prikaz igre gdje kolo srece slaze recenicu"
        imgTitle="Kolo srece"
      />
      <Text as="h2">Savjeti za kraj</Text>
      <Text as="p">
        I za kraj, stari dobro poznati savjet da treba biti uporan, stavljamo na
        srce i djeci i roditeljima. Glas R ne rješava se preko noći. Bit će
        trenutaka kada možda pomislite da je uloženo puno truda, a niste
        nagrađeni niti jednim vidljivim korakom naprijed. Tada se sjetite da se
        niti do jednog dragocjenog znanja ne dolazi lako. Sjajni novčić u rukama
        sjajan je zato što je u njega uložen neizmjeran trud, a time svi
        postajemo svjesni koliko zapravo vrijedi! Krenite što prije, naše more
        zahtijeva puno upornosti i hrabrosti, ali vi to već imate, jer prijavili
        ste se za ovu glasovnu avanturu! Prijavite se i na besplatnu probu za{' '}
        <BlogLink>Kokolingo</BlogLink> i pogledajte kako vam se sviđa naša karta
        s blagom!
      </Text>
      <SectionBlog />
    </PageNew>
  );
};

export default PracticeVoiceR;
